import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { Button, Typography } from '@popmenu/common-ui';
import { createEvent } from '~/utils/eventable';
import { inGroupsOf, sortByKey } from '../../utils/arrays';
import { calculateDistance, isValidCoordinates } from '../../utils/geography';
import { formatPhone, nl2br, toLocationMapUrl } from '../../utils/utils';
import { themeShape, withTheme } from '../../utils/withTheme';
import { withIntl } from '../../utils/withIntl';
import { withWindowContext } from '../../shared/WindowProvider';
import { withWindowSizeContext } from '../../shared/WindowSizeProvider';
import { classNames, withStyles } from '../../utils/withStyles';

import CustomContent from '../shared/CustomContent';
import DirectionsButton from '../locations/DirectionsButton';
import LocationAddressDisplay from '../shared/LocationAddressDisplay';
import Divider from '../../admin/shared/forms/Divider';
import SocialIcon from '../../shared/SocialIcon';
import LocationHours from '../shared/LocationHours';
import Map from '../../shared/Map/ScrolledMap';
import CustomImg from '../../shared/CustomImg';
import MultiSectionLazyContainer from '../../shared/MultiSectionLazyContainer';
import { AH, AHLevelProvider } from '../shared/AccessibleHeading';
import { readableFontColor } from '../../utils/colors';
import { useUserCoordinates } from '../shared/hooks/UserCoordinates';

const locationSearchSectionStyles = theme => ({
  buttonStyle: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: `${readableFontColor(theme.palette.primary.main, theme.palette.text.primary)} !important`,
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    color: `${readableFontColor(theme.palette.primary.main, theme.palette.text.primary)} !important`,
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
    padding: theme.spacing(1.5),
  },
  locationSearchSectionImg: {
    // maxWidth needs to override .fr-view img max-width applied to CustomImg.jsx
    maxWidth: '50% !important',
  },
  locationSearchSectionImgBottom: {
    '@media (max-width: 992px)': {
      maxWidth: '50% !important',
    },
  },
});

const CustomContentLinkButtons = ({ classes, location }) => {
  if (location.customContentLinks.length === 0) return null;

  return (
    <div>
      {location.customContentLinks.map(linkHandle => (
        <Button
          aria-label={`${linkHandle.name} for ${location.name}${linkHandle.isExternal ? ', opens in a new window' : ''}`}
          className={classNames(classes.buttonStyle, 'location-links')}
          href={linkHandle.url}
          size="small"
          target={linkHandle.isExternal ? '_blank' : '_self'}
          key={linkHandle.name}
        >
          {linkHandle.name}
        </Button>
      ))}
    </div>
  );
};

const LocationSearchSection = (props) => {
  const [userCoordinates, setUserCoordinates] = useUserCoordinates();
  const { classes } = props;
  const locationSearchListComponents = [];

  const onUserLocationChange = (lat, lng) => setUserCoordinates({ lat, lng });

  // Return locations sorted by distance from user location, if available
  const validLocations = useMemo(() => {
    const locations = props.locations.filter(location => isValidCoordinates && location.isLocationEnabled);
    if (isValidCoordinates(userCoordinates)) {
      return locations.map(location => ({
        ...location,
        distance: calculateDistance(location, userCoordinates),
      })).sort((a, b) => a.distance - b.distance);
    }
    return locations.map(location => ({ ...location })).sort((a, b) => a.stateName.localeCompare(b.stateName));
  }, [props.locations, userCoordinates]);

  const stateNames = [...new Set(validLocations.map(loc => loc.stateName))];
  if (validLocations.length === 0) {
    return null;
  }

  const formatDistance = (distance) => {
    const { t } = props;
    if (distance && userCoordinates.lat && userCoordinates.lng) {
      const measure = distance.toFixed(1);
      const distanceUnitsAway = t('consumer.distance.measure_units_away', {
        measure,
        units: (measure === '1.0' ? t('consumer.distance.mile') : t('consumer.distance.miles')),
      });
      return <p>{distanceUnitsAway}</p>;
    }
    return null;
  };

  const renderOpeningRanges = (firstDayOfWeek, ranges, holidayOpeningRanges, isLocationClosed) => {
    if (validLocations.length > 0) {
      return <LocationHours ranges={ranges} holidayRanges={holidayOpeningRanges} firstDayOfWeek={firstDayOfWeek} isLocationClosed={isLocationClosed} />;
    }
    return null;
  };

  const renderSearchMap = () => (
    <Map
      defaultZoomLevel={props.defaultZoomLevel}
      mapTheme={props.mapTheme}
      markers={validLocations.map(location => ({
        city: location.city,
        googlePlaceId: location.googlePlaceId,
        id: location.id,
        label: location.name,
        lat: location.lat,
        lng: location.lng,
        phone: location.displayPhone,
        postalCode: location.postalCode,
        state: location.state,
        streetAddress: location.streetAddress,
        useCloudflareMapboxUrl: location.useCloudflareMapboxUrl,
      }))}
      onUserLocationChange={onUserLocationChange}
      restaurant={props.restaurant}
      showSearchBox
      snapUserLocationOnInit
      userLocation={userCoordinates}
    />
  );

  const renderLocationSection = location => (
    <section>
      {location.photoUrl && location.showLocationPhotoInLocationSearch && (
      <CustomImg
        alt={location.name}
        src={location.photoUrl}
        className={props.locationSectionLayout === 'lls_left' ? classes.locationSearchSectionImg : classes.locationSearchSectionImgBottom}
      />
      )}
      <AH variant="h4">{location.name}</AH>
      <AHLevelProvider>
        <React.Fragment>
          {props.showDirectionsButton ? (
            <React.Fragment>
              <Typography style={{ marginBottom: '16px' }} gutterBottom><LocationAddressDisplay location={location} />
              </Typography>
              <Typography gutterBottom><DirectionsButton location={location} /></Typography>
            </React.Fragment>
          ) : (
            <Typography style={{ marginBottom: '16px' }}>
              <Link className="address-link" aria-label={`Get Directions to ${location.name} on Google Maps (opens in a new window)`} to={{ pathname: toLocationMapUrl(location) }} rel="noopener" target="_blank">
                <LocationAddressDisplay location={location} />
              </Link>
            </Typography>
          )}
          {props.locationSectionLayout === 'lls_left' && <br />}
        </React.Fragment>
        {formatDistance(location.distance)}
        {location.displayPhone && (
        <p>
          <a
            aria-label={`${location.displayPhone} ${location.name} ${props.t('generic.telephone')}`}
            href={`tel:${location.displayPhone}`}
            onClick={() => {
              createEvent({
                eventableId: location.id,
                eventableType: 'RestaurantLocation',
                eventType: 'call_attempt_event',
                restaurantId: props.restaurant.id,
              });
            }}
          >
            <span>{formatPhone(location.displayPhone)}</span>
          </a>
        </p>
        )}
        {location.customLocationContent && (
        <CustomContent id={`location-search-content-${location.id}`} html={location.customLocationContent} />
        )}
        {props.showLocationCustomButtons && <CustomContentLinkButtons classes={classes} location={location} />}
        {renderOpeningRanges(location.firstDayOfWeek, location.openingRanges, location.holidayOpeningRanges, location.isLocationClosed)}
        {location.socialHandles && (
        <div className="social" style={{ fontSize: 'x-large', marginTop: '15px' }}>
          {sortByKey(location.socialHandles, 'position').map(handle => (
            <a
              aria-describedby="aria-new-window-2"
              aria-label={props.t(`restaurants.social_handles.social_type_actions.${handle.socialType}`)}
              href={handle.url}
              key={`social_${handle.id}`}
              rel="noopener"
              target="_blank"
            >
              <SocialIcon icon={handle.socialType} />
            </a>
          ))}
        </div>
        )}
        {validLocations.length > 1 && (
        <hr />
        )}
      </AHLevelProvider>
    </section>
  );

  if (props.locationSectionLayout !== 'lls_left') {
    stateNames.forEach((stateName) => {
      const shouldRenderStateHeading = stateNames.length > 1 && props.displayLocationStateHeading;
      if (shouldRenderStateHeading) {
        const stateNameHeaderComponent = (
          <React.Fragment>
            <AH variant="h3">{stateName}</AH>
            <Divider style={{ marginTop: '0px' }} />
          </React.Fragment>
        );
        locationSearchListComponents.push(stateNameHeaderComponent);
      }
      inGroupsOf(validLocations.filter(loc => loc.stateName === stateName), 3).forEach((group, i) => {
        const locationGroupComponent = (
          <div key={i} className="row">
            <AHLevelProvider>
              {group.map(location => (
                <div key={location.id} className="col-md-4 col-xs-12 pm-location">
                  {renderLocationSection(location)}
                </div>
              ))}
            </AHLevelProvider>
          </div>
        );
        locationSearchListComponents.push(locationGroupComponent);
      });
    });
  }

  return (
    <div id="locations" tabIndex="-1">
      <div className="row location">
        <div className="col-xs-12">
          {!props.heading && (
            <div className="pm-location-search-heading">
              <AH variant="h3">{validLocations.length === 1 ? 'Our Location' : 'Our Locations'}</AH>
            </div>
          )}
          {props.locationContent && (
            <div className="pm-location-search-heading">
              <p>{nl2br(props.locationContent)}</p>
            </div>
          )}
        </div>
      </div>
      <AHLevelProvider>
        {props.locationSectionLayout === 'lls_left' ? (
          <div className="row location">
            <div className="col-md-7 col-md-push-5 col-xs-12">
              {renderSearchMap()}
            </div>
            <div className="col-md-5 col-md-pull-7 col-xs-12">
              <div className="pm-map-wrap pm-location-search-list">
                <MultiSectionLazyContainer
                  firstHiddenSection={1}
                >
                  {validLocations.map(location => (
                    <div key={location.id} className="row">
                      <div className="col-xs-12">
                        {renderLocationSection(location, classes)}
                      </div>
                    </div>
                  ))}
                </MultiSectionLazyContainer>
              </div>
            </div>
          </div>
        ) :
          (
            <React.Fragment>
              <div className="row">
                <div className="col-xs-12">
                  {renderSearchMap()}
                </div>
              </div>
              <div className="pm-location-search-list">
                <MultiSectionLazyContainer
                  firstHiddenSection={1}
                >
                  {locationSearchListComponents.map((locationSearchListComponent, index) => (
                    <React.Fragment key={index}>
                      {locationSearchListComponent}
                    </React.Fragment>
                  ))}
                </MultiSectionLazyContainer>
              </div>
            </React.Fragment>
          )}
      </AHLevelProvider>
    </div>
  );
};

LocationSearchSection.defaultProps = {
  heading: null,
  locationContent: null,
};

LocationSearchSection.propTypes = {
  addScrollListener: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  defaultZoomLevel: PropTypes.number.isRequired,
  heading: PropTypes.string,
  locationContent: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    displayPhone: PropTypes.string,
    googlePlaceId: PropTypes.string,
    id: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    name: PropTypes.string,
    phone: PropTypes.string,
    postalCode: PropTypes.string,
    socialHandles: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      socialType: PropTypes.string,
      url: PropTypes.string,
    })),
    state: PropTypes.string,
    streetAddress: PropTypes.string,
  })).isRequired,
  locationSectionLayout: PropTypes.string.isRequired,
  mapTheme: PropTypes.string.isRequired,
  removeScrollListener: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
  theme: themeShape.isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowScrolled: PropTypes.bool.isRequired,
};

export default memo(compose(
  withTheme,
  withStyles(locationSearchSectionStyles),
  withIntl,
  withWindowContext,
  withWindowSizeContext,
  mapProps(({ window, windowSize, ...props }) => ({
    ...props,
    addScrollListener: window.addScrollListener,
    removeScrollListener: window.removeScrollListener,
    windowHeight: windowSize.innerHeight,
    windowScrolled: window.scrolled,
  })),
)(LocationSearchSection));
