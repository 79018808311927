import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { addDays, endOfDay, parseISO } from 'date-fns';

import { sortByKey } from '../../utils/arrays';
import { nl2br } from '../../utils/utils';
import { formatTime } from '../../utils/time';
import { withIntl } from '../../utils/withIntl';
import { classNames } from '../../utils/withStyles';

const enumeratedTimeValues = ['Open 24-hours', 'Closed'];

const toTimeRange = (r) => {
  const formattedRanges = [r.openTime, r.closeTime].map((rawTime, timeIndex) => {
    const time = rawTime;
    if (time === null) {
      return null;
    }
    if (r.untilClose && timeIndex === 1) {
      return 'Close';
    }
    return formatTime(time);
  });
  return formattedRanges.filter(Boolean).join(' - ');
};

// make screen readers more audibly friendly
// remove spaces, remove preceeding '0' and trailing '00', and read hyphen as 'to'
// better enunciate am/pm
const formattedScreenReaderTimes = (time) => {
  if (enumeratedTimeValues.includes(time)) {
    return time;
  }
  return time.replace(/^0/g, '').replace(/:00/g, '').replace(/\s/g, '').replace(/-/g, ' to ')
    .replace(/([ap])m/g, ' $1m');
};

const LocationHours = (props) => {
  const { ranges, holidayRanges, firstDayOfWeek, isLocationClosed } = props;
  if (!ranges || (ranges.length === 0 && !isLocationClosed)) {
    return null;
  }

  // rotate days so specified day of the week is first
  const fdowRotate = (arr, count) => {
    let num = count;
    num -= arr.length * Math.floor(num / arr.length);
    arr.push(...arr.splice(0, num));
    return arr;
  };
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const fDow = firstDayOfWeek.slice(5, firstDayOfWeek.length);
  fdowRotate(days, days.indexOf(fDow));

  // Build hour ranges for each day
  const hoursByDay = {};
  days.forEach((day) => {
    const dayRanges = ranges.filter(r => r.days.indexOf(day) > -1);
    let times = sortByKey(dayRanges, 'openTime').map(r => toTimeRange(r)).filter(Boolean);
    if (dayRanges.some(r => r.openAllDay)) {
      times = ['Open 24-hours'];
    } else if (times.length === 0) {
      times = ['Closed'];
    }
    dayRanges.forEach((dayRange) => {
      if (dayRange.additionalHoursContent) {
        const additionalHours = dayRange.additionalHoursContent.split('\n');
        times.push(...additionalHours);
      }
    });
    hoursByDay[day] = times;
  });

  const holidayHours = [];
  holidayRanges.map((h) => {
    const { holidayDate, holidayName } = h;
    // Only display upcoming holidays
    const showHoliday = parseISO(holidayDate) <= addDays(new Date(), 45) && new Date() <= endOfDay(parseISO(holidayDate));
    // Hide dates not in range
    if (!showHoliday) {
      return false;
    }
    const timeRange = toTimeRange(h) || 'Closed';
    return holidayHours.push([holidayName, timeRange]);
  });

  // Combine consecutive days with same hours
  const combinedDays = [];
  if (props.displaySeparateDays) {
    days.forEach((day) => {
      const times = hoursByDay[day];
      combinedDays.push([
        props.t(`models.restaurant/location/opening_range.day_${day}`),
        times,
      ]);
    });
  } else {
    for (let i = 0; i < days.length; i += 1) {
      const day = days[i];
      const mergedDays = [day];
      const times = hoursByDay[day];
      for (let j = i + 1; j < days.length; j += 1) {
        if (String(hoursByDay[days[j]]) === String(times)) {
          mergedDays.push(days[j]);
          i += 1;
        } else {
          break;
        }
      }
      if (mergedDays.length > 1) {
        const firstDay = props.t(`models.restaurant/location/opening_range.day_${mergedDays[0]}`);
        const lastDay = props.t(`models.restaurant/location/opening_range.day_${mergedDays[mergedDays.length - 1]}`);
        combinedDays.push([
          `${firstDay} - ${lastDay}`,
          times,
        ]);
      } else {
        combinedDays.push([
          props.t(`models.restaurant/location/opening_range.day_${day}`),
          times,
        ]);
      }
    }
  }

  return (
    <div className={classNames(props.className, 'hours')}>
      {isLocationClosed ?
        (
          <span className="hours-day">
            <FormattedMessage id="ordering.temp_closed" defaultMessage="Temporarily Closed" />
          </span>
        ) :
        (
          <React.Fragment>
            {combinedDays.map(([day, times]) => (
              <div key={day} className="hours-entry">
                <div className="hours-times">
                  <span className="hours-day">
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    {day}:
                  </span>
                  {times.map((time, i) => {
                    if (i === 0) {
                      return (
                        <span key={time} className="hours-time" aria-label={formattedScreenReaderTimes(time)}> {nl2br(time)}</span>
                      );
                    }
                    return (
                      <div key={time} className="hours-additional" aria-label={formattedScreenReaderTimes(time)}> {nl2br(time)}</div>
                    );
                  })}
                </div>
              </div>
            ))}
            {holidayHours.map(([name, holidayTime]) => (
              <div key={name} className="hours-entry">
                <div className="hours-times">
                  <span className="hours-day">
                    {/* eslint-disable-next-line react/jsx-no-literals */}
                    {name}:
                  </span>
                  <span className="hours-time"> {holidayTime}</span>
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
    </div>
  );
};

LocationHours.defaultProps = {
  className: null,
  displaySeparateDays: false,
  firstDayOfWeek: 'fdow_monday',
  holidayRanges: [],
  ranges: [],
};

LocationHours.propTypes = {
  className: PropTypes.string,
  displaySeparateDays: PropTypes.bool,
  firstDayOfWeek: PropTypes.string,
  holidayRanges: PropTypes.arrayOf(PropTypes.shape()),
  ranges: PropTypes.arrayOf(PropTypes.shape({
    closeTime: PropTypes.number,
    days: PropTypes.arrayOf(PropTypes.string),
    openTime: PropTypes.number,
  })),
  t: PropTypes.func.isRequired,
};

export default withIntl(LocationHours);
